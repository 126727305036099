export default [
  {
    id: 1,
    name: 'Vegan',
  },
  {
    id: 2,
    name: 'Poultry',
  },
  {
    id: 3,
    name: 'Fish',
  },
  {
    id: 4,
    name: 'Vegetarian',
  },
  {
    id: 5,
    name: 'Meat',
  },
];
