<template>

  <div>
    <template v-if="edit">
      <form :class="{'was-validated': $v.filter.$dirty}" @submit.prevent="validate">
        <div class="form-group row d-flex">
          <label class="col-sm-3 col-form-label form-label text-right">Weekly Menu</label>
          <div class="col-sm-7">
            <select
              v-model="$v.filter.weeklyMenu.$model"
              :class="{'is-invalid': $v.filter.weeklyMenu.$error}"
              class="form-control custom-select">
              <option :value="null" disabled>Select weekly menu</option>
              <option value="create">Create new concept menu</option>
              <option
                v-for="week in weeklyMenus"
                :key="week.id"
                :value="week">
                {{weekName(week.startDate, {weekFrom: false})}} ({{week.status}})
              </option>
            </select>
            <template v-if="$v.filter.weeklyMenu.$dirty">
              <div v-if="!$v.filter.weeklyMenu.required" class="invalid-feedback d-block">Weekly menu is required</div>
            </template>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-auto">
            <button class="form-control btn btn-primary">Next</button>
          </div>
        </div>
      </form>
    </template>
    <template v-else>
      <form-description label="Weekly menu" :value="weekName(filter.weeklyMenu.startDate, {weekFrom: false})"/>
    </template>
  </div>

</template>

<script>

import FormDescription from '@/components/FormDescription';
import {required} from 'vuelidate/lib/validators';

export default {
  name: 'ScheduleMenuFilter',
  components: {
    FormDescription,
  },
  props: {
    edit: {
      default: true,
      type: Boolean,
    },
    filter: {
      required: true,
      type: Object,
    },
    weeklyMenus: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    validate() {
      this.$v.filter.$touch();

      if (!this.$v.filter.$invalid) {
        this.$emit('valid', this.filter);
      }
    },
  },
  validations: {
    filter: {
      weeklyMenu: {
        required,
      },
    },
  },
};

</script>
