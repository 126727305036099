<template>
  <div class="px-0">
    <div class="row">
      <div class="col">
        <div class="card border-0 m-0">
          <div class="card-body" :class="[{ 'p-0 p-2': themes.length }, { 'p-7': loading && !themes.length }]">
            <dimmer :active="loading">
              <div v-if="themes.length" class="d-flex flex-column theme-list">
                <div
                  v-for="theme in themes"
                  :key="theme.id"
                  class="theme-item px-4 cursor-pointer"
                  @click="$emit('onSelected', theme)">
                  <div class="d-flex justify-content-between align-items-center gap-24">
                    <div class="d-flex gap-8 align-items-center">
                      <i class="text-success fe fe-plus" style="font-size: 1.5rem"></i>
                      <div class="d-flex align-items-center gap-8">
                        <img
                          v-if="theme.mobile_banner"
                          :src="getTransformationUrl(theme.mobile_banner, { height: 50, width: 50, crop: 'fill' })"
                          class="avatar avatar-lg"/>
                        <span
                          v-else
                          class="avatar avatar-lg"
                          :style="`background-image: url(${identicon(theme.id)});`"></span>
                        <div>
                          <a
                            :href="`/themes/${theme.id}`"
                            target="_blank"
                            rel="noreferrer noopener"
                            @click.stop>{{theme.name}}</a>
                          <div>
                            <span
                              class="tag text-uppercase font-weight-bold"
                              :class="[theme.status === THEME_STATUS.ready ? 'tag-success' : 'tag-secondary']">
                              {{theme.status}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span class="tag">
                        {{theme.recipes_count}} recipes
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="!loading" class="text-center p-4">
                <h3>No themes found</h3>
              </div>
              <div
                v-show="hasNextPage"
                ref="load"
                class="text-center list-spinner mt-2">
                <div class="spinner"></div>
              </div>
            </dimmer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import infiniteScroll from '@/mixins/infiniteScroll';
import {themes} from '@/services';

const THEME_STATUS = {
  draft: 'draft',
  ready: 'ready',
};

export default {
  name: 'AddThemeList',
  mixins: [infiniteScroll],
  data() {
    return {
      THEME_STATUS,
      withRelation: ['mobileBanner'],
      withCount: ['recipes'],
    };
  },
  computed: {
    themes() {
      return this.data.items ?? [];
    },
  },
  async created() {
    try {
      this.loading = true;
      this.data = await this.fetch(0);
      this.onOpen();
    }
    catch (error) {
      console.error(error);
    }
    finally {
      this.loading = false;
    }
  },
  methods: {
    async fetch(page) {
      return themes.getByParameters({
        page,
        limit: 20,
        query: this.search,
        with: this.withRelation.join(','),
        with_count: this.withCount.join(','),
      });
    },
    nextPage(page) {
      return this.fetch(page);
    },
  },
};
</script>

<style scoped>
.avatar-md {
  min-width: 2.5em;
}

.theme-item {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 0.5px solid #E0E5EC;
  border-bottom: 0.5px solid #E0E5EC;
}

.theme-item:first-child {
  border-top: 0;
}

.theme-item:last-child {
  border-bottom: 0;
}

.theme-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.spinner,
.spinner:before,
.spinner:after {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  left: 0;
  top: 0;
}

.list-spinner {
  display: flex;
  justify-content: center;
}
</style>
