<template>

  <div>
    <div v-if="value.length" class="filtered-items small mr-2 d-flex align-items-center">
      <div
        v-for="(item, index) in value"
        :key="index"
        class="d-flex">
        <span class="selected-badge ml-2 d-flex align-items-center">
          {{item.name}}<i class="fe fe-x ml-1 cursor-pointer" @click.prevent="removeFilter(index, item)"/>
        </span>
      </div>
      <button class="btn btn-link" @click.prevent="resetFilters">Reset</button>
    </div>
    <div class="dropdown custom-menu">
      <button
        class="btn btn-secondary dropdown-toggle"
        data-toggle="dropdown"
        @click.prevent>
        <i class="fe fe-filter"/> Filter by
      </button>
      <div class="dropdown-menu dropdown-menu-right">
        <div class="dropdown-item">
          <div
            v-for="item in options"
            :key="item.model"
            class="form-group">
            <label class="form-label text-capitalize">{{item.label}}</label>
            <autocomplete
              :value="selected[item.model]"
              :list="{items: item.options}"
              :multiple="item.multiple"
              :entity="item.label"
              :placeholder="`Select ${item.label}`"
              label="name"
              local
              @input="(newValue) => setValue(newValue, item.model)"
            />
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-sm btn-link" @click.prevent="resetFilters">Reset</button>
            <button class="btn btn-sm btn-primary" @click.prevent="$emit('search')">Search</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Autocomplete from '@/components/Autocomplete';

export default {
  name: 'TableFilters',
  components: {
    Autocomplete,
  },
  props: {
    value: {
      required: true,
      type: Array,
    },
    options: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selected: {},
    };
  },
  computed: {
    selections: {
      get() { return this.selected; },
      set(newValue) {
        this.$emit('input', newValue);
        this.selected[newValue.tagGroupId] = newValue;
      },
    },
  },
  methods: {
    removeFilter(index, model) {
      this.$emit('remove', index);
      const selectedFilters = this.selected[model.groupId].filter(item => item.id !== model.id);
      this.selected = Object.assign({}, this.selected, {[model.groupId]: selectedFilters});
    },
    resetFilters() {
      this.$emit('reset');
      this.selected = {};
    },
    setValue(newValue, model) {
      this.$emit('input', newValue);
      this.selected = Object.assign(this.selected, {[model]: newValue});
    },
  },
};
</script>

<style scoped>
  .custom-menu button.dropdown-toggle,
  .custom-menu button.dropdown-toggle:focus,
  .custom-menu button.dropdown-toggle:active,
  .custom-menu button.dropdown-toggle:visited {
      outline: none;
  }
  .custom-menu button.dropdown-toggle:hover,
  .custom-menu button.dropdown-toggle:focus,
  .custom-menu button.dropdown-toggle:visited {
    background: inherit;
  }
  .custom-menu .dropdown-item {
    min-width: 30rem;
  }
  .custom-menu .dropdown-item:hover,
  .custom-menu .dropdown-item:focus,
  .custom-menu .dropdown-item:active {
    background: initial;
    color: initial;
  }
  .selected-badge {
    padding: .25rem;
    margin: .15rem;
    font-size: 12px;
    color: #039be5;
    background: #e3f2fd;
    border: 0;
    border-radius: 2px;
    white-space: normal;
    cursor: pointer;
  }
</style>
