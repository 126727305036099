<template>
  <b-modal
    v-model="isShowModal"
    :scrollable="true"
    :title="`Add swaps to ${recipe.name}`"
    hide-footer
    size="lg"
  >

    <template slot="modal-header-close"><wbr></template>

    <dimmer :active="isLoading">
      <div class="table-responsive">
        <table class="table table-hover table-outline table-vcenter card-table">
          <thead>
            <tr>
              <th class="w-1"></th>
              <th>Recipe Name</th>
              <th class="text-nowrap">Historical data</th>
              <th class="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(recipe, index) in swaps"
              :key="index"
              class="cursor-pointer"
              @click="() => $emit('select', recipe)">
              <td class="text-center">
                <div class="item-action">
                  <i class="text-success fe fe-plus" style="font-size: 1.5rem"></i>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <a
                    v-if="recipe.photo"
                    :href="recipe.photo.url"
                    target="_blank"
                    rel="noreferrer noopener"
                    @click.stop>
                    <img :src="getTransformationUrl(recipe.photo, {height: 50, width: 50, crop: 'fill'})" class="avatar avatar-md"/>
                  </a>
                  <div class="ml-2">
                    <a
                      :href="`/recipes/${recipe.id}`"
                      target="_blank"
                      rel="noreferrer noopener"
                      @click.stop>{{recipe.name}}</a>
                    <div class="text-muted d-flex flex-wrap custom-gap my-1">
                      <small><strong>Calories:</strong> {{getCalories(recipe)}}</small>
                      <small><strong>Cooking time:</strong> {{recipe.cooking_time}} mins.</small>
                      <small><strong>Cost/serving:</strong> AED {{numeral(recipe.cost).format('0,0.00')}}</small>
                      <small><strong>Frequency:</strong> {{recipe.frequency}}</small>
                      <small><strong>Last published:</strong> {{recipe.lastPublished ? moment(recipe.lastPublished).format('DD-MM-YYYY') : 'N/A'}}</small>
                    </div>
                    <div class="list-tags d-flex flex-wrap custom-gap">
                      <template v-if="recipe.tags.length">
                        <small
                          v-for="tag in recipe.tags"
                          :key="tag.id"
                          class="tag tag-primary">{{tag.name.toLowerCase()}}</small>
                      </template>
                      <small v-if="recipe.weeklyClassic" class="tag tag-warning">weekly-classic</small>
                      <small
                        v-for="feature in recipe.features"
                        :key="feature.id"
                        class="tag tag-warning">{{feature.name}}</small>
                      <small
                        v-for="feature in recipe.dynamic_features"
                        :key="feature"
                        class="tag tag-warning">{{feature}}</small>
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-nowrap">
                <p class="mb-0">Uptake: {{recipe.historicalUptakes}}</p>
                <p class="mb-0">Rating: {{numeral(recipe.avgRating).format('0,0[.]00') || 0}} / 5</p>
              </td>
              <td class="text-center">
                <div>{{statusName(recipe.status)}}</div>
              </td>
            </tr>

            <tr v-if="!swaps.length">
              <td colspan="100%" class="text-center">
                <div class="pt-4">
                  <h3 v-if="isLoading">Loading...</h3>
                  <div v-else>
                    <h3 class="mb-1"> No swappable recipes found </h3>
                    <span class="text-muted">
                      <b>Note</b>: Swappable recipes may be scheduled already
                    </span>
                  </div>
                </div>
              </td>
            </tr>

            <tr v-if="swaps.length && swaps.length !== recipe.remainingSwapCount">
              <td colspan="100%" class="text-center">
                <span class="text-muted">
                  <b>Note</b>: Other swappable recipes may be scheduled already
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </dimmer>

  </b-modal>

</template>

<script>
import {startCase} from 'lodash';
import {weeklyMenus} from '@/services';

const NUTRITION_CALORIES = 'Calories';

export default {
  name: 'ScheduleMenuSwapList',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    recipe: {
      type: Object,
      default: () => ({}),
    },
    startDate: {
      type: String,
      default: '',
    },
    linkedSwaps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      swaps: [],
      isLoading: false,
    };
  },
  computed: {
    isShowModal: {
      get: function() { return this.isShow; },
      set: function(newValue) { this.$emit('show', newValue); },
    },
  },
  watch: {
    isShowModal: async function(newValue) {
      if (newValue) {
        this.swaps = [];
        await this.fetchSwaps();
      }
    },
  },
  methods: {
    getCalories(recipe) {
      return recipe.nutritions?.find(nutrition => nutrition.name === NUTRITION_CALORIES)?.value || 'N/A';
    },
    statusName(name) {
      return startCase(name);
    },
    async fetchSwaps() {
      this.isLoading = true;

      const payload = {
        startDate: this.startDate,
        mealPlan: this.recipe.mealPlan,
        swappable_recipes_for_recipe: this.recipe.id,
      };

      try {
        const {suggestions} = await weeklyMenus.suggest(payload);
        this.swaps = suggestions.filter(it => !this.linkedSwaps.includes(it.id));
      }
      catch (error) {
        console.error(error);
        this.isShowModal = false;
      }
      finally {
        this.isLoading = false;
      }
    },
  },
};

</script>

<style scoped>
  .custom-gap {
    gap: 5px;
  }
  .avatar-md {
    min-width: 2.5em;
  }
</style>
