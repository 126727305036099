<template>
  <label class="custom-switch">
    <input
      :value="value"
      :checked="value"
      :disabled="disabled"
      type="checkbox"
      class="custom-switch-input"
      @input="event => $emit('input', event.target.checked)"
    >
    <span class="custom-switch-indicator"></span> <span class="ml-2">{{valueLabel}}</span>
  </label>
</template>

<script>
export default {
  name: 'HSwitch',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueLabel() {
      return this.value ? 'Yes' : 'No';
    },
  },
};
</script>
