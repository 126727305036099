<template>
  <div>
    <button
      type="button"
      class="dropdown-item cursor-pointer"
      @click.prevent="showModal = true">
      <i class="dropdown-icon fe fe-box"></i> Archive
    </button>

    <b-modal
      v-model="showModal"
      hide-footer
      title="Are you sure you want to archive?">
      <template slot="modal-header-close"><wbr/></template>
      <h5 class="mb-4">{{recipe.name}}</h5>
      <form>
        <div class="row align-items-center">
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">
                <span class="text-danger">* </span>Archive reason
              </label>
              <select
                v-model="recipe.archive_reason"
                :class="`form-control ${validator.errors().has('reason') ? 'is-invalid' : ''}`"
              >
                <option value="" selected>Select reason</option>
                <option value="Article discontinued">Article discontinued</option>
                <option value="Concept">Concept</option>
                <option value="Cost">Cost</option>
                <option value="Flavour">Flavour</option>
                <option value="High calories">High calories</option>
                <option value="Ingredient issue">Ingredient issue</option>
                <option value="Method/Convenience">Method/Convenience</option>
                <option value="Value for money">Value for money</option>
              </select>
              <div
                v-if="validator.errors().has('reason')"
                class="invalid-feedback">
                {{validator.errors().first('reason')}}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="form-label">Comments</label>
              <textarea
                v-model="recipe.archive_comments"
                :class="`form-control ${validator.errors().has('comments') ? 'is-invalid' : ''}`"
              />
              <div
                v-if="validator.errors().has('comments')"
                class="invalid-feedback">
                {{validator.errors().first('comments')}}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end pt-6">
          <button class="btn btn-secondary mr-2" @click.prevent="showModal = false">Cancel</button>
          <button
            v-b-tooltip="'No way back'"
            :disabled="archiving"
            class="btn btn-danger"
            @click.prevent="archive">
            <i
              v-if="archiving"
              class="fe fe-refresh-cw mr-2"
              :class="{'spin-icon': true}"></i>
            Archive
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>

import {recipes} from '@/services';

export default {
  name: 'ArchiveRecipe',
  props: {
    recipe: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      archiving: false,
      validator: this.$bodyValidator,
      rules: {
        reason: [
          'required',
          'string',
        ],
        comments: [
          'nullable',
          'string',
        ],
      },
    };
  },
  methods: {
    async archive() {
      const params = {
        reason: this.recipe.archive_reason,
        comments: this.recipe.archive_comments,
      };

      this.validator.setData(params).setRules(this.rules);

      if (!this.validator.validate()) {
        return;
      }

      this.archiving = true;

      try {
        await recipes.deleteById(this.recipe.id, {params});

        this.$toasted.success('Recipe successfully archived.');

        this.showModal = false;

        this.$emit('archived');
      }
      catch (e) {
        this.$toasted.error('Something went wrong when archiving the recipe');
        console.error(e);
      }
      finally {
        this.archiving = false;
      }
    },
  },
};
</script>
