import {mlForecast, weeklyMenus} from '@/services';
import {upperFirst} from 'lodash';
import {captureException} from '@sentry/vue';

const ESTIMATED_WAITING_MINS = 2;
const MENU_SIZE = 34;
const MAX_AUTO_REFRESH_ATTEMPTS = 10;
const AUTO_REFRESH_SECONDS = 5;

export default {
  watch: {
    uptakes(value) {
      if (value?.length) {
        this.setFoodCostRate();
      }
    },
  },
  computed: {
    /**
     * Checks if the menu has uptakes
     * @returns {boolean}
     */
    hasUptakes() {
      return this.uptakes?.length > 0;
    },
    /**
     * Checks if the menu is populated.
     * For the menu to be populated, there has to be exactly 20 primary recipes
     * @returns {boolean}
     */
    menuPopulated() {
      return this.week.recipes.filter(r => r.id && r.is_default).length === MENU_SIZE;
    },
    shouldShowForecastTools() {
      return ['concept', 'draft'].includes(this.week.status) &&
      this.menuPopulated && !this.forecastJobIsDone &&
       (!this.hasUptakes || this.abandonmentRate === null);
    },
    shouldShowRequestForecast() {
      return this.uptakeJobStatus === null;
    },
    forecastJobIsProcessing() {
      return this.uptakeJobStatus === 'processing';
    },
    forecastJobIsDone() {
      return this.uptakeJobStatus === 'done';
    },
    formattedRecipeIds() {
      // concatenate this week's recipe IDs string
      return this.week?.recipes.map(r => r.id).join(',');
    },
  },
  data() {
    return {
      MENU_SIZE,
      loadingFoodCost: false,
      foodCost: null,
      uptakeJobStatus: null,
      uptakes: [],
      loadingAbandonmentRate: false,
      abandonmentRate: null,
      tooltips: {
        request: `Request a fresh forecast for the current menu, it may take up to ${ESTIMATED_WAITING_MINS} minutes for the results to be ready.`,
        recheck: 'Check if the forecast results for this menu are ready.',
      },
      maxRefreshCount: -1,
      autoRefreshSeconds: AUTO_REFRESH_SECONDS,
    };
  },
  methods: {
    startAutoRefresh() {
      // if the auto refresh is already running, don't start another one
      if (this.maxRefreshCount !== -1) return;

      this.maxRefreshCount = MAX_AUTO_REFRESH_ATTEMPTS;
      const countDown = setInterval(async () => {
        // clear the interval if the job is done
        if (this.uptakeJobStatus === 'done') {
          return clearInterval(countDown);
        }

        this.autoRefreshSeconds -= 1;

        if (this.autoRefreshSeconds < 0) {
          this.autoRefreshSeconds = AUTO_REFRESH_SECONDS;
          this.maxRefreshCount -= 1;
          await this.getMenuUptake();
        }

        if (this.maxRefreshCount === 0 || !this.forecastJobIsProcessing) {
          clearInterval(countDown);
        }
      }, 1000);
    },
    resetMenuForecastState() {
      this.loadingFoodCost = false;
      this.foodCost = null;
      this.uptakes = [];
      this.loadingAbandonmentRate = false;
      this.abandonmentRate = null;
    },
    resetAllMenuForecastState() {
      this.resetMenuForecastState();
      this.uptakeJobStatus = null;
      this.maxRefreshCount = -1;
    },
    async forecastWeek() {
      if (!this.menuPopulated) {
        return this.$toasted.error(`${upperFirst(this.week.status)} menu must have ${MENU_SIZE} primary recipes before requesting a forecast`);
      }

      // request uptake forecast
      const menuUptakesReq = await mlForecast.requestMenuUptake({
        menu_id: this.week.id,
        recipe_ids: this.formattedRecipeIds,
        start_date: this.filter.weeklyMenu.startDate,
      });

      // forecast requested and api already have results for this menu variation
      if (menuUptakesReq?.status === 'done') {
        return this.checkWeekForecastResults();
      }

      // set the job statuses
      this.uptakeJobStatus = 'processing';

      this.$toasted.success(`Forecast requested successfully, please allow ${ESTIMATED_WAITING_MINS} minutes for the results to be ready`);
      this.startAutoRefresh();
      this.getMenuAbandonmentRate();
    },
    async recheckWeekForecastResults() {
      this.checkWeekForecastResults();
      this.startAutoRefresh();
    },
    async checkWeekForecastResults() {
      this.resetMenuForecastState();
      this.getMenuUptake();
      this.getMenuAbandonmentRate();
    },
    async getMenuUptake() {
      const menuUptakesResponse = await mlForecast.fetchMenuUptakes({
        menu_id: this.week.id,
        recipe_ids: this.formattedRecipeIds,
        start_date: this.filter.weeklyMenu.startDate,
      });

      // update the uptake forecast results
      this.uptakeJobStatus = menuUptakesResponse.status;
      this.uptakes = menuUptakesResponse.uptakes || [];
    },
    async getMenuAbandonmentRate() {
      this.loadingAbandonmentRate = true;
      try {
        const menuAbandonmentResponse = await mlForecast.fetchMenuAbandonment({
          menu_id: this.week.id,
          recipe_ids: this.formattedRecipeIds,
        });

        // update the abandonment rate forecast results
        this.abandonmentRate = menuAbandonmentResponse || null;
      }
      catch (error) {
        captureException(error);
      }
      finally {
        this.loadingAbandonmentRate = false;
      }
    },
    getRecipeUptake(recipeId) {
      const result = this.uptakes.find(i => i.recipe_id === recipeId);
      if (result) { return result.uptake + '%'; }
      return 'N/A';
    },
    async setFoodCostRate() {
      this.loadingFoodCost = true;
      try {
        const {food_cost} = await weeklyMenus.foodCost({
          uptakes: this.uptakes,
        });
        this.foodCost = food_cost;
      }
      finally {
        this.loadingFoodCost = false;
      }
    },
  },
};
