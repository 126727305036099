<template>
  <div class="form-group">
    <div class="input-group">
      <select
        v-model="selectedValue"
        class="form-control custom-select">
        <option :value="null" disabled>Sort by</option>
        <option
          v-for="option in options"
          :key="option.model"
          :value="option">
          {{option.label}}
        </option>
      </select>
      <div class="input-group-append">
        <select
          v-if="selectedValue"
          :value="selectedValue.direction"
          class="form-control custom-select"
          @input="evt => changeDirection(evt.target.value, selectedValue)">
          <option value="asc">Asc</option>
          <option value="desc">Desc</option>
        </select>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-sm btn-link" @click.prevent="$emit('reset')">Reset</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SortDropwdown',
  props: {
    value: {
      type: Object,
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    changeDirection(direction, selectedValue) {
      this.$emit('sort-direction', {direction, selectedValue});
    },
  },
};
</script>

<style scoped>
.custom-menu .dropdown-item {
  min-width: 40rem;
}
.custom-menu button.dropdown-toggle,
.custom-menu button.dropdown-toggle:focus,
.custom-menu button.dropdown-toggle:active,
.custom-menu button.dropdown-toggle:visited {
  outline: none;
}
.custom-menu .dropdown-item:hover,
.custom-menu .dropdown-item:focus,
.custom-menu .dropdown-item:active {
  background-color: transparent;
  color: initial;
}
</style>
